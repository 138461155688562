import * as React from 'react';
import getTranslations from '../../utils/getTranslations';
import './increaseRetention.scss';
import { DownloadButton, DownloadType } from '../shared/downloadButton';
import { BrowserView, MobileView, isAndroid, isIOS } from 'react-device-detect';
import { graphql, StaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

const query = graphql`
  query {
    desktop: file(relativePath: { eq: "increaseretention.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export const IncreaseRetention: React.FC = props => {
  const { increaseRetention } = getTranslations().clubOwner!;
  return (
    <StaticQuery
      query={query}
      render={data => {
        const imageData = data.desktop.childImageSharp.fluid;
        return (
          <BackgroundImage
            fluid={imageData}
            backgroundColor='#03032b'
            className='increase-retention main-padding rounded-bottom-corner'
            critical={true}
          >
            <div className='column is-6'>
              <h1>{increaseRetention!.title}</h1>
              <span>{increaseRetention!.subtitle}</span>
              <BrowserView>
                <div id='buttons'>
                  <DownloadButton type={DownloadType.iOS} white={true} />
                  <DownloadButton type={DownloadType.Android} white={true} />
                </div>
              </BrowserView>
              <MobileView>
                {isAndroid && (
                  <DownloadButton type={DownloadType.Android} white={true} />
                )}
                {isIOS && (
                  <DownloadButton type={DownloadType.iOS} white={true} />
                )}
              </MobileView>
            </div>
          </BackgroundImage>
        );
      }}
    />
  );
};

import React from 'react';
import getTranslations from '../../utils/getTranslations';
import { ImgWithTitle } from '../shared/imgWithTitle';
import './supportedEquipment.scss';
import { TitleAndSubtitle } from '../shared/titleAndSubtitle';
import stackMachine from '../../../assets/illustrations/stack-machine.svg';
import barbell from '../../../assets/illustrations/barbells.svg';
import cardioMachine from '../../../assets/illustrations/cardio-machine.svg';
import handWeight from '../../../assets/illustrations/weight.svg';

export const SupportedEquipment: React.FC = props => {
  const { supportedEquipment } = getTranslations().clubOwner!;

  return (
    <div id='supported-equipment' className='main-padding'>
      <div id='text'>
        <TitleAndSubtitle
          title={supportedEquipment!.title!}
          subtitle={supportedEquipment!.subtitle!}
        />
      </div>

      <div className='columns'>
        <div className='column is-3'>
          <ImgWithTitle
            imgUrl={stackMachine}
            text={supportedEquipment!.stackMachines!}
          />
        </div>
        <div className='column is-3'>
          <ImgWithTitle
            imgUrl={cardioMachine}
            text={supportedEquipment!.cardioMachines!}
          />
        </div>
        <div className='column is-3'>
          <ImgWithTitle imgUrl={barbell} text={supportedEquipment!.barbells!} />
        </div>
        <div className='column is-3'>
          <ImgWithTitle
            imgUrl={handWeight}
            text={supportedEquipment!.handWeightAreas!}
          />
        </div>
      </div>
    </div>
  );
};

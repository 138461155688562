import axios from 'axios';

export interface IEmail {
  email: string;
  message: string;
  subject?: string;
}

export const sendEmail = async (email: IEmail) => {
  if (process.env.NODE_ENV === 'development') {
    // tslint:disable-next-line: no-console
    console.log('email sent! (not really though, this is dev mode');
    // tslint:disable-next-line: no-console
    console.log(email.message);
  } else {
    await axios.post('/api/emailqueue/send_contact_beefit_tracker_website', {
      ...email
    });
  }
};

import React from 'react';
import {
  Formik,
  FormikActions,
  FormikProps,
  Form,
  Field,
  FieldProps
} from 'formik';
import './contactForm.scss';
import getTranslations from '../../utils/getTranslations';
import { sendEmail } from '../../utils/sendEmail';

interface IContactFormValues {
  email: string;
  title: string;
  message: string;
  agree: boolean;
}

export const ContactForm: React.FC<{
  title: string;
  subtitle: string;
  mailTo: string;
}> = props => {
  const {
    titleOptional,
    yourEmail,
    yourMessage,
    sendMessage,
    messageSent
  } = getTranslations().contact!;

  const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false);

  const innerForm = (
    <Formik
      initialValues={{ email: '', title: '', message: '', agree: false }}
      onSubmit={(values: IContactFormValues) => {
        const { email, message, title } = values;
        sendEmail({ email, message, subject: title });
        setIsSubmitted(true);
      }}
      render={(formikBag: FormikProps<IContactFormValues>) => (
        <Form>
          <Field
            name='email'
            render={({ field, form }: FieldProps<IContactFormValues>) => (
              <div className='field'>
                <label className='label custom-label'>{yourEmail}</label>
                <input type='text' {...field} className='input' />
              </div>
            )}
          />
          <Field
            name='title'
            render={({ field, form }: FieldProps<IContactFormValues>) => (
              <div className='field'>
                <label className='label custom-label'>{titleOptional}</label>
                <input type='text' {...field} className='input' />
              </div>
            )}
          />
          <Field
            name='message'
            render={({ field, form }: FieldProps<IContactFormValues>) => (
              <div className='field'>
                <label className='label custom-label' id='label'>
                  {yourMessage}
                </label>
                <div className='control'>
                  <textarea className='textarea' {...field} />
                </div>
              </div>
            )}
          />
          <div>
            <button
              id='submit-form-button'
              disabled={
                !formikBag.isValid || formikBag.values.message.length < 1
              }
              className='button is-rounded is-white'
              type='submit'
            >
              {sendMessage}
            </button>
          </div>
        </Form>
      )}
    />
  );

  return (
    <div id='contact'>
      <div className='column is-6 is-offset-3' style={{}}>
        <div className='has-text-centered' id='text'>
          <h2>{props.title}</h2>
          <span id='subtitle'>{props.subtitle}</span>
        </div>
        {!isSubmitted && <div style={{ width: '100%' }}>{innerForm}</div>}
        {isSubmitted && (
          <div style={{ minHeight: '398px' }} className='has-text-centered'>
            <span>{messageSent}</span>
          </div>
        )}
      </div>
    </div>
  );
};

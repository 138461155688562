import React from 'react';
import './imgWithTitle.scss';

export const ImgWithTitle: React.FC<{
  imgUrl: string;
  text: string;
}> = props => {
  return (
    <div id='img-with-title'>
      <img src={props.imgUrl} />
      <span>{props.text}</span>
    </div>
  );
};

import React from 'react';
import './clubownerContact.scss';
import getTranslations from '../../utils/getTranslations';
import { ContactForm } from '../shared/contactForm';

export const ClubOwnerContact: React.FC = props => {
  const { soundsInteresting } = getTranslations().clubOwner!;

  return (
    <div>
      <ContactForm
        mailTo='jonas@benefittech.dk'
        title={soundsInteresting!.title!}
        subtitle={soundsInteresting!.subtitle!}
      />
    </div>
  );
};

import * as React from 'react';
import { IncreaseRetention } from '../components/clubowner/increaseRetention';
import '../layout/layout.scss';
import { HowItWorksClubowner } from '../components/clubowner/howItWorksClubowner';
import { Sensors } from '../components/clubowner/sensors';
import { SupportedEquipment } from '../components/clubowner/supportedEquipment';
import {
  SixTileInfoBox,
  IInfoBoxProps
} from '../components/shared/sixTileInfoBox';
import getTranslations from '../utils/getTranslations';
import { ClubOwnerContact } from '../components/clubowner/clubownerContact';
import affordability from '../../assets/atom/icon/benefit/affordability.svg';
import attendance from '../../assets/atom/icon/benefit/attendance.svg';
import revenue from '../../assets/atom/icon/benefit/revenue.svg';
import space from '../../assets/atom/icon/benefit/space.svg';
import data from '../../assets/atom/icon/benefit/data.svg';
import workout from '../../assets/atom/icon/benefit/workout.svg';

const ClubOwnerPage: React.FC = () => {
  const { beefitCreatesValue } = getTranslations().clubOwner!;
  const b = beefitCreatesValue;

  const infoBoxes: IInfoBoxProps[] = [
    {
      title: b!.affordabilityTitle!,
      subtitle: b!.affordabilitySubtitle!,
      imgUrl: affordability
    },
    {
      title: b!.betterUseOfSpaceTitle!,
      subtitle: b!.betterUseOfSpaceSubtitle!,
      imgUrl: space
    },
    {
      title: b!.higherAttendanceTitle!,
      subtitle: b!.higherAttendanceSubtitle!,
      imgUrl: attendance
    },
    {
      title: b!.bigDataTitle!,
      subtitle: b!.bigDataSubtitle!,
      imgUrl: data
    },
    {
      title: b!.physicalRevenueTitle!,
      subtitle: b!.physicalRevenueSubtitle!,
      imgUrl: revenue
    },
    {
      title: b!.personalTrainingTitle!,
      subtitle: b!.personalTrainingSubtitle!,
      imgUrl: workout
    }
  ];

  return (
    <div>
      <IncreaseRetention />
      <HowItWorksClubowner />
      <Sensors />
      <SupportedEquipment />
      <SixTileInfoBox
        title={b!.title!}
        subtitle={b!.subtitle!}
        infoBoxes={infoBoxes}
      />
      <ClubOwnerContact />
    </div>
  );
};

export default ClubOwnerPage;

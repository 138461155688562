import React from 'react';
import getTranslations from '../../utils/getTranslations';
import { ImgWithTitle } from '../shared/imgWithTitle';
import './sensors.scss';
import { TitleAndSubtitle } from '../shared/titleAndSubtitle';
import selector from '../../../assets/photo/1.png';
import scanner from '../../../assets/photo/2.png';
import senseModule from '../../../assets/photo/3.png';

export const Sensors: React.FC = props => {
  const { sensors } = getTranslations().clubOwner!;

  return (
    <div id='sensors' className='main-padding'>
      <div id='text'>
        <TitleAndSubtitle
          title={sensors!.title!}
          subtitle={sensors!.subtitle!}
        />
      </div>
      <div className='columns'>
        <div className='column is-4 has-text-centered'>
          <ImgWithTitle imgUrl={selector} text='Beefit Selector' />
          <span className='sensor-description'>
            {sensors!.selectorDescription}
          </span>
        </div>
        <div className='column is-4 has-text-centered'>
          <ImgWithTitle imgUrl={scanner} text='Beefit Scanner' />
          <span className='sensor-description'>
            {sensors!.scannerDescription}
          </span>
        </div>
        <div className='column is-4 has-text-centered'>
          <ImgWithTitle imgUrl={senseModule} text='Sense Module' />
          <span className='sensor-description'>
            {sensors!.senseModuleDescription}
          </span>
        </div>
      </div>
    </div>
  );
};
